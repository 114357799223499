<template>
  <div></div>
</template>

<script>
export default {
  name: "CredentialSuccess",
  created() {
    window.opener.callback(this.$route.query)
    window.close()
  }
}
</script>
